export const APP_URL = "https://backend.mishrilalsahu.in.net";
export const CURRENT_URL = "https://mishrilalsahu.in.net";
// export const CURRENT_URL = "http://localhost:5000";
// export const APP_URL = "http://localhost:5000";

export const truncateText = (text, limit) => {
  if (text.length <= limit) {
    return text;
  }
  return text.substring(0, limit) + '...';
};

export const numberToFormalLanguage=(num)=>{
  if (num < 1000) {
    return num.toString();
  }

  const lookup = [
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "m" },
    { value: 1e9, symbol: "b" },
    { value: 1e12, symbol: "t" },
  ];

  for (let i = lookup.length - 1; i >= 0; i--) {
    if (num >= lookup[i].value) {
      return (num / lookup[i].value).toFixed(1) + lookup[i].symbol;
    }
  }

  return num.toString();
}