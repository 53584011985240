import React, { Fragment, useState, useEffect } from 'react'
import { Breadcrumb } from '../../CommonElements/Breadcrumb'
import { APP_URL, truncateText } from '../../utils/Constant';

const Projects = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchProject = async (page = 1, search = "") => {
    setIsLoading(true);
    try {
      const response = await fetch(`${APP_URL}/project/app/get/filter?currentPage=${page}&search=${search}`);
      if (response.ok) {
        const jsonData = await response.json();
        setData(jsonData.data);
        setPage(Number(jsonData.page) || 1);
        setTotalPages(Math.ceil(jsonData.total / jsonData.limit) || 1);
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchProject(page, searchTerm);
  }, [page, searchTerm]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); 
  };
  const handlePagination = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };
  return (
    <Fragment>
      <Breadcrumb title={'Projects'} />
      <section className="text-gray-600 bg-[#252734f5] body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="mb-6 flex justify-between items-center">
            <input
              type="text"
              className="p-2 rounded-lg bg-gray-200 text-gray-800"
              placeholder="Search Projects"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          {isLoading ? (
            <div className="flex justify-center min-h-[200px] max-h-[400px] p-8 ">
              <img src="/loader.svg" alt="Loader" className="h-full w-auto" />
            </div>
          ) : (
            <React.Fragment>
              <div className="flex flex-wrap -m-4">
                {data &&
                  data.map((item, index) => <ProjectCard key={"project"+index} item={item} />)}
              </div>
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePagination={handlePagination}
              />
            </React.Fragment>
          )}
        </div>
      </section>
    </Fragment>
  )
}
const Pagination = ({ page, totalPages, handlePagination }) => {
  return (
    <div className="flex justify-center mt-8">
      <button
        onClick={() => handlePagination(page - 1)}
        disabled={page === 1}
        className={`mx-2 px-3 py-1 bg-gray-700 text-white rounded-lg ${page === 1 && 'disabled:opacity-50'}`}
      >
        Previous
      </button>
      <span className="text-gray-300">{`Page ${page} of ${totalPages}`}</span>
      <button
        onClick={() => handlePagination(page + 1)}
        disabled={page === totalPages}
        className={`mx-2 px-3 py-1 bg-gray-700 text-white rounded-lg ${page === totalPages && 'disabled:opacity-50'}`}
      >
        Next
      </button>
    </div>
  );
};

export const ProjectCard = (props) => {
  return (
    <div className="relative p-4 md:w-1/3 xl:w-1/4">
      <div className="h-full bg-[#252734] shadow-md rounded-lg overflow-hidden relative">
          <div className="absolute top-2 left-2 bg-gradient-to-r from-pink-500 to-red-600 text-white text-xs font-bold py-1 px-3 rounded-full z-10 shadow-lg">
            Coming Soon
          </div>

        <img
          className="lg:h-48 md:h-36 w-full object-cover object-center"
          src={`${APP_URL}/${props?.item?.thumbnail}`}
          onError={(e) => (e.target.src = "/blog-thumbnail.png")}
          alt={props?.item?.title}
        />

        <div className="p-6">
          <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
            {props.item?.category?.name}
          </h2>
          <h1 className="title-font text-lg font-medium text-white mb-3">
            {props?.item?.title}
          </h1>
          <p className="leading-relaxed max-h-[80px] overflow-y-clip mb-3">
            {truncateText(props.item?.metaDescription || "", 80)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Projects
