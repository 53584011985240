import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./styles.css";
import { EffectCoverflow, Pagination } from "swiper/modules";
import { APP_URL, numberToFormalLanguage, truncateText } from "../../utils/Constant";
import { Link } from "react-router-dom";

export function Blog() {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchBlog = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${APP_URL}/blog/app/get/filter?currentPage=1&isHome=true&search=`);
      if (response.ok) {
        const jsonData = await response.json();
        setData(jsonData.data);
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchBlog();
  }, []);
  return (
    <section className="swiper-blog px-4 xl:px-10 bg-[#2a2c39] md:py-8 py-4 xl:py-10">
      <div className="text-slate-400 text-lg  py-2">|| My Blogs</div>
      <div className="xl:text-5xl text-2xl my-2 md:my-6 md:text-4xl text-white font-bold">
        My Experience
      </div>
      <div className="py-14 md:px-14 px-6">
        {isLoading ? (
          <div className="flex justify-center min-h-[200px] max-h-[400px] p-8 ">
            <img src="/loader.svg" alt="Loader" className="h-full w-auto" />
          </div>
        ) :
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            pagination={true}
            loop={true}
            autoplay={{
              delay: 1000,
              disableOnInteraction: false,
            }}
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper"
          >
            {data.map((el, index) => {
              return (
                <SwiperSlide key={"home-blog" + index}>
                  <div className="h-full bg-[#252734] shadow-md rounded-lg overflow-hidden">
                    <img
                      className="lg:h-48 md:h-36 w-full object-cover object-center"
                      src={`${APP_URL}/${el.thumbnail}`}
                      onError={(e) => (e.target.src = "/blog-thumbnail.png")}
                      alt={el.title}
                    />
                    <div className="p-6">
                      <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                        {el.category}
                      </h2>
                      <h1 className="title-font text-lg font-medium text-white mb-3">
                        {el.title}
                      </h1>
                      <p className="leading-relaxed max-h-[80px] text-[#d00a32] overflow-y-clip mb-3">{truncateText(el.metaDescription || '', 80)}</p>
                      <div className="flex items-center flex-wrap ">
                        <Link
                          to={`Blogs/${el.slug}`}
                          className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0"
                        >
                          Learn More
                          <svg
                            className="w-4 h-4 ml-2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M5 12h14"></path>
                            <path d="M12 5l7 7-7 7"></path>
                          </svg>
                        </Link>
                        <span className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
                          <svg
                            className="w-4 h-4 mr-1"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            viewBox="0 0 24 24"
                          >
                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                            <circle cx="12" cy="12" r="3"></circle>
                          </svg>
                          {numberToFormalLanguage(el?.views || 1000)}
                        </span>
                        <span className="text-gray-400 inline-flex items-center leading-none text-sm">
                          <svg
                            className="w-4 h-4 mr-1"
                            stroke="currentColor"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            viewBox="0 0 24 24"
                          >
                            <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                          </svg>
                          {el?.commentCount}
                        </span>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        }
      </div>
    </section>
  );
}
