import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./styles.css";
import { EffectCoverflow, Pagination } from "swiper/modules";
import { APP_URL, truncateText } from "../../utils/Constant";

export function Project() {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchProject = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${APP_URL}/project/app/get/filter?currentPage=1&isHome=true`);
      if (response.ok) {
        const jsonData = await response.json();
        setData(jsonData.data);
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchProject();
  }, []);
  return (
    <section className="swiper-project px-4 xl:px-10 bg-[#2a2c39] md:py-8 py-4 xl:py-10">
      <div className="text-slate-400 text-lg  py-2">|| My Projects</div>
      <div className="xl:text-5xl text-2xl my-2 md:my-6 md:text-4xl text-white font-bold">
        My Creations For Myself.
      </div>
      <div className="py-14 md:px-14 px-6">
        {isLoading ? (
          <div className="flex justify-center min-h-[200px] max-h-[400px] p-8 ">
            <img src="/loader.svg" alt="Loader" className="h-full w-auto" />
          </div>
        ) :
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            pagination={true}
            loop={true}
            autoplay={{
              delay: 1000,
              disableOnInteraction: false,
            }}
            modules={[EffectCoverflow, Pagination]}
            className="mySwiper"
          >
            {data.map((el, index) => {
              return (
                <SwiperSlide key={"home-project" + index}>
                  <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                    <div className="absolute top-2 left-2 bg-gradient-to-r from-pink-500 to-red-600 text-white text-xs font-bold py-1 px-3 rounded-full z-10 shadow-lg">
                      Coming Soon
                    </div>
                    {/* <a href={`${el?.slug}`}> */}
                    <img
                      className="rounded-t-lg max-w-[300px]"
                      src={`${APP_URL}/${el?.thumbnail}`}
                      onError={(e) => (e.target.src = "/blog-thumbnail.png")}
                      alt={el?.title}
                      title={el?.title}
                    />
                    {/* </a> */}
                    <div className="p-5">
                      {/* <a href={`${el?.slug}`}> */}
                      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        {el?.title}
                      </h5>
                      {/* </a> */}
                      <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                        {truncateText(el?.metaDescription || "", 80)}
                      </p>
                      {/* <a
                      href={el.link}
                      className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-[#3d3dd2] rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Read more
                      <svg
                        className="w-3.5 h-3.5 ml-2"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M1 5h12m0 0L9 1m4 4L9 9"
                        />
                      </svg>
                    </a> */}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        }
      </div>
    </section>
  );
}
