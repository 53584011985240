
import Header from './Components/Header'
import { Footer } from './Components/Footer'
import { Outlet } from 'react-router-dom'

const Layout = () => {
  return (
    <div style={{minHeight:'100%'}}>
      <Header />
       <Outlet />
      <Footer />
    </div>
  )
}

export default Layout
